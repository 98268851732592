.newButtons {
  margin-right: 1rem;
  cursor: pointer;
  border-radius: 5em;
  color: #fff;
  background: linear-gradient(to right, #037fff, #47a3ff);
  border: 0;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-family: "Ubuntu", sans-serif;
  font-size: 13px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.04);
  transition: 0.3s;
}

.newButtons:hover {
  background: linear-gradient(to right, #9c27b0, #e040fb);
}

h5 {
  font-size: 1.7rem;
  font-weight: 400;
  color: white;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #037fff;
    cursor: pointer;
  }
}

.portfolio {
  width: 100%;
  padding: 1rem;
  overflow: hidden;
  align-content: center;
  justify-content: center;
  transition: 0.3s;
}

li {
  text-decoration: none;
  list-style: none;
}

.image-data img {
  padding: 2rem;
}

.image-data {
  background-image: linear-gradient(135deg,#54545441,#171717b1);
  border-radius: 20px;
  color: #fbfff7;
  padding-bottom: 1rem;
  width: 100%;
  padding: 1rem;
  overflow: hidden;
  transition: 0.3s;
}

.image-data:hover {
  background-color: #47a3ff;
  box-shadow: none;
  color: #161925 !important;
  transform: translate(4px, 3px);
  box-shadow: inset 5em 1em #47a3ff;
}

.portfolios-content{
  margin-left: -2rem;
}

.experiencePage {
  background-image: linear-gradient(135deg,#54545441,#171717b1);
  border-radius: 20px;
  padding: 2rem;
}

.experiencePage img {
  padding: 1rem;
  padding-bottom: 2rem;
}

.row { 
  text-align: left;
  max-width: max(100%, 500px);
  padding-top: 0.75rem;
}

.col {
  padding: 0 15px; 
}

.col > * {
  list-style: none;
  padding: 0px;
}

.col > * > li {
  color: white;
  padding-left: 35px;
  position: relative;
  margin-bottom: 15px;
}

ul li:before {
  content: '◁';
  position: absolute;
  border-radius: 50%;
  top: 5px;
  left: 15px;
  margin-top: -2px;
  font-size: small;
}

.li_included {
  font-size: medium;
}

.experience_page_title {
margin-bottom: 3rem;
}
