.BlogsPage {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
  }
  @media screen and (max-width: 592px) {
    font-size: 0.75rem;
  }
}

.b-title {
  margin-bottom: 4rem;
  @media screen and (max-width: 592px) {
    margin-bottom: 10rem;
  }
}

.blog {
  background-image: linear-gradient(135deg,#54545441,#171717b1);
  border-radius: 20px;
  color: #fbfff7;
  padding-bottom: 1rem;
  width: 100%;
  padding: 1rem;
  overflow: hidden;
  transition: 0.3s;
  @media screen and (max-width: 592px) {
    font-size: 0.75rem;
  }
}

.blog:hover {
  background-color: #47a3ff;
  box-shadow: none;
  color: #161925 !important;
  transform: translate(4px, 3px);
  box-shadow: inset 5em 1em #47a3ff;
}

.blog-content {
  overflow: hidden;
  padding-bottom: 0.2rem;
  text-align: center;
  @media screen and (max-width: 592px) {
    font-size: 0.75rem;
  }
  

  .blog-link {
    color: inherit;
    font-family: inherit;
    text-decoration: none;
    font-size: 2rem;
    padding-bottom: 1rem;
    transition: all 0.4s ease-in-out;
    &:hover {
      color: #037fff;
    }
  }
}

img {
  width: 100%;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  padding-bottom: 0.3rem;
}

a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  @media screen and (max-width: 592px) {
    font-size: 0.75rem;
  }
}

.blog a:hover {
  box-shadow: none;
  color: #161925 !important;
}
