.NavBar {
  height: 100vh;
  .nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .profile {
      width: 100%;
      text-align: center;
      margin-top: 4rem;

      img {
        width: 90%;
        border-radius: 100%;
      }
    }

    .nav-items {
      width: 100%;
      .nav-item {
        list-style: none;
        text-align: center;
        a {
          text-decoration: none;
          font-size: inherit;
          color: inherit;
          display: block;
          padding: 0.5rem 0;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0.4rem;
            height: 100%;
            background-color: #0381ff33;
            transform-origin: bottom;
            transform: scale(0);
            overflow-x: hidden;
            transition: transform 0.25s,
              0.15s width 0.15s cubic-bezier(1, -0.16, 0.6, 1.35);
          }

          &:hover::before {
            width: 100%;
            transform: scale(1);
          }
        }
      }
    }

    .footer {
      width: 100%;
      text-align: center;
      justify-content: center;
      p {
        text-align: center;
        padding: 1rem 0;
        font-size: 0.6rem;
      }
    }
  }
}

.active {
  background-color: #037fff;
}

.dark-mode-btn {
  text-align: center;
  padding: 1rem 0;
  font-size: 0.6rem;
  padding: 4px;
  color: white;
  margin-top: 5px;
  font-size: 30px;
  font-weight: bold;
  border: none;
  text-align: center;
  border-radius: 20px;
  background-color: rgb(255, 255, 255, 0);
}

.dark-mode-btn:hover {
  color: #915dd1;
}

.none {
  left: -300px;
}
