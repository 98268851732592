.HomePage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  transition: 00.3s;
}

.hero-text {
  color: white;
  text-align: center;
  font-size: 3.6rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 590px) {
    font-size: 2.2rem;
    }
}

.link-header{
  color: white;
  text-align: center;
}

.hero-text span {
  color: #037fff;
}

.h-sub-text {
  text-align: center;
  color: rgb(240, 240, 240);
  margin-bottom: 1rem;
  @media screen and (max-width: 590px) {
    font-size: 1.1rem;
    }
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;

  @media screen and (max-width: 620px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    text-align: center;
    padding: 1.2rem;
  }

  @media screen and (max-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    text-align: center;
    padding: 1.2rem;
    width: 80%;
    height: 80%;
    margin: 0 5rem;
  }
}

.linktree{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;

  display: grid;
  gap: 1rem;
  text-align: center;
  :hover {
    background-color: white;
    color: black;
    transition: 0.3s;
    .homepage-icon{
      color: black;
      border-radius: 0em;
    }
  }
}

.linktree-icon {
  border: #fff solid 2px;
  border-radius: 2em;
  padding: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.link-icon {
  color: white;
  padding: 0.5rem;
  font-size: 1.75rem;
  border-radius: 0em;
}

.link-icon:hover{
    color: rgb(211, 211, 211);
    transition: 0.3s;
  }

.link-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
}

.icon {
  margin: 0 1rem;
  cursor: pointer;
  border-radius: 5em;
  color: #fff;
  background: linear-gradient(to right, #037fff, #47a3ff);
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  font-family: "Ubuntu", sans-serif;
  font-size: 1rem;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.04);
  transition: 0.3s;

  @media screen and (max-width: 620px) {
    margin-left: -2.6rem;
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 500px) {
    @media screen and (max-width: 500px) {
    margin-top: 0.3rem;
    font-size: 0.85rem;
    height: 100%;
    }
    }
}

.homepage-icon{
  text-align: center;
  justify-self: center;
  align-self: center;
  justify-content: center;
  color: white;
  margin-left: 0.5rem;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
}

.icon:hover {
  background: linear-gradient(to right, #9c27b0, #e040fb);
}

.hovitems {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
}

.link-image {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
    img {
        border-radius: 100%;
        margin: auto;
        width: 30%;
      }
}

.LinkTree {
  margin: 10%;
  @media screen and (max-width: 620px) {
    margin: none;
  }
}
