.App {
  .sidebar {
    transition: 0.3s;
    width: 16%;
    height: 100vh;
    background-color: #191d2bf5;
    background-image: url(../images/dots.svg);
    position: fixed;
    z-index: 11;
    transform-origin: left;
    @media screen and (max-width: 1000px) {
      transition: all 0.4s ease-in-out;
      transform: translateX(-100%);
      width: 30%;
    }
    @media screen and (max-width: 411px) {
      width: 50%;
    }
  }
  .nav-toggle {
    transform: translateX(-100%);
    transition: 0.3s;
    width:40%;
    height: 100vh;
    background-color: #191d2bf5;
    background-image: url(../images/dots.svg);
    position: fixed;
    z-index: 12;
    transform-origin: left;
    overflow: hidden;
    @media screen and (max-width: 1000px) {
      transition: all 0.4s ease-in-out;
      transform: translateX(0);
    }
  }
  
  .main-content {
    transition: 0.3s;
    width: 84%;
    margin-left: 16%;
    background-color: #10121bf9;
    min-height: 100vh;
    background-image: (url(../images/Gradient-Blur-2.jpg));
    background-size: cover;
    background-position: center;
    display: grid;
    position: relative;
    overflow: hidden;
    .content {
      z-index: 11!important;
      margin: 5rem 15rem;
      @media screen and (max-width: 1100px) {
        margin: 2rem 10rem;
      }
      @media screen and (max-width: 1400px) {
        margin: 3rem 10rem;
      }
      @media screen and (max-width: 1400px) {
        margin: 3rem 10rem;
      }
      @media screen and (max-width: 710px) {
        margin: 2rem 3rem;
      }
    }
    @media screen and (max-width: 1000px) {
      margin-left: 0;
      width: 100%;
    }
  }
  .main-content-margin-left-adj{
    transition: 0.3s;
    width: 100%;
    background-color: #10121bf9;
    min-height: 100vh;
    background-image: url(../images/Gradient-Blur-2.jpg);
    background-size: cover;
    background-position: center;
    display: grid;
    position: relative;
    overflow: hidden;
    .content {
      z-index: 11!important;
      margin: 5rem 15rem;
      @media screen and (max-width: 1100px) {
        margin: 2rem 10rem;
      }
      @media screen and (max-width: 1400px) {
        margin: 3rem 10rem;
      }
      @media screen and (max-width: 1400px) {
        margin: 3rem 10rem;
      }
      @media screen and (max-width: 710px) {
        margin: 2rem 3rem;
      }
    }
    @media screen and (max-width: 1000px) {
      margin-left: 0;
      width: 100%;
    }
  }
}

.nav-btn {
  position: fixed;
  z-index: 13;
  right: 10%;
  top: 5%;
  width: 4rem;
  height: 4rem;
  display: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  display: block;
  .lines-1,
  .lines-2,
  .lines-3 {
    height: 0.4rem;
    width: 100%;
    background-color: #037fff;
    pointer-events: none;
    border-radius: 20px;
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}
