.ServicesSection {
  margin-right: 10px;

  .service {
    width: 95%;
    background-color: #191d2b;
    border-left: 1px solid #2e344e;
    border-right: 1px solid #2e344e;
    border-bottom: 1px solid #2e344e;
    border-top: 8px solid #2e344e;
    transition: all 0.4s ease-in-out;
    @media screen and (max-width: 1400px) {
      width: 100%;
      margin-bottom: 1rem;
    }
    &:hover {
      border-top: 8px solid #037fff;
    }

    .service-content {
      padding: 1rem;
      .s-title {
        font-size: 1.6rem;
        font-weight: 500;
        position: relative;
        padding-bottom: 1rem;
        margin: 1rem 0;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 30%;
          height: 1px;
          background-color: #2e344e;
        }
      }
    }
  }
}

.servives-container {
  justify-content: space-between;
  margin-top: 4rem;
  @media screen and (max-width: 1400px) {
    flex-direction: column;
    margin-bottom: 2rem;
  }
}

.servives-container img {
  width: 40vw;
  width: 100%;
  object-fit: cover;
  height: 30vh;

  padding: 5px;
}

hr {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  width: 60%;
}
