.Title {
  text-transform: uppercase;
  color: white;
  font-size: 2rem;
  position: relative;
  padding-bottom: 2rem;
  letter-spacing: 3px;
}

.Title::before {
  content: "";
  bottom: 0;
  position: absolute;
  left: 0;
  background-color: #0381ff54;
  width: 6rem;
  height: 0.3rem;
  border-radius: 50px;
}

.Title::after {
  content: "";
  bottom: 0;
  position: absolute;
  left: 0;
  background-color: #037fff;
  width: 3rem;
  height: 0.3rem;
}

.Title span {
  position: absolute;
  top: 15%;
  left: 0;
  font-size: 4.5rem;
  opacity: 0.07;
}
