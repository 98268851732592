.SkillsSection {
  margin: 0.75rem 0;
}

.skill-header{
  display: flex;
}
.skills-container {
  .skill-title {
    font-size: 1.4rem;
    font-weight: 400;
  }

  .skill-bar {
    display: flex;
    align-items: center;
  }
}

.language-icon{
  margin-top: 0.15rem;
  color: white;
  margin-left: 1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
}

.s-text {
  font-size: 1rem;
}

.inner-progress {
  border-radius: 3px;
  position: absolute;
  background-color: #037fff;
  height: 100%;
  bottom: 0;
  left: 0;
}

.progress {
  border-radius: 3px;
  width: 20rem;
  height: 0.5rem;
  background-color: #037fff33;
  margin-left: 0px;
  position: relative;
  .inner-progress {
    position: absolute;
    background-color: #037fff;
    height: 100%;
    bottom: 0;
    left: 0;
  }
}

.progress::after {
  content: "";
  position: absolute;
  border-radius: 3px;
  height: 100%;
  bottom: 0;
  left: 0;
  background-color: #037fff;
}
