
.contact{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;
  margin: 80px 10%;
}

.contact-section{
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.contact-left{
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-basis: 40%;
}
.contact-left h1{
  font-size: 50px;
  font-weight: 700;
  background: linear-gradient(to right, #037fff, #47a3ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contact-left p{
  max-width: 550px;
  color: #D8D8D8;
  font-size: 16px;
  line-height: 25px;
}
.contact-details{
  display: flex;
  flex-direction: column;
  gap: 25px;
  color: #D8D8D8;
  font-size: 22px;
}
.contact-detail{
  display: flex;
  align-items: center;
  gap: 18px;
}
.contact-detail img{
  width: 25px;
}
.contact-right{
  flex-basis: 55%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 15px;
}

.contact-right label{
  color: #D8D8D8;

}

.contact-right input, .contact-right textarea{
  border: none;
  width: 100%;
  padding: 18px 0;
  padding-left: 20px;
  border-radius: 4px;
  background: #32323c;
  color: #A0A0A0;
  font-family: Outfit;
  font-size: 18px;
}


.contact-submit{
  border: none;
  color: white;
  border-radius: 50px;
  background: linear-gradient(to right, #037fff, #47a3ff);
  font-size: 18px;
  padding: 18px 40px;
  margin-bottom: 50px;
  margin-top: 15px;
  cursor: pointer;
  transition: 0.3s;
}
.contact-submit:hover {
  background: linear-gradient(to right, #9c27b0, #e040fb);
}
@media(max-width:768px){
  .contact{
      gap: 40px;
      margin: 50px 6%;
      align-items: start;
  }
 
  .contact-section{
      flex-direction: column;
  }
  .contact-left h1{
      font-size: 35px;
  }
 
  .contact-detail{
      gap: 12px;
  }
  .contact-detail img{
      width: 20px;
  }
  .contact-left{
      margin-bottom: 30px;
  }
  .contact-right label{
      font-size: 14px;
  }
 
  
}